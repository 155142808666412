<template>
    <div>
        <div class="login-top">
            <div class="lang">
                <select :value="language" :v-model="language" @change="language = $event.target.value">
                    <option v-for="lang in languages" :value="lang" :data="lang" :key="lang">{{ lang }}</option>
                </select>
            </div>
        </div>
        <div class="download-page">
            <div class="bubbles" v-if="!isMobile">
                <div class="bubble bubble-1"></div>
                <div class="bubble bubble-2"></div>
                <div class="bubble bubble-3"></div>
                <div class="bubble bubble-4"></div>
                <div class="bubble bubble-5"></div>
            </div>

            <div class="bubbles" v-else>
                <div class="bubble bubble-mobile-1"></div>
                <div class="bubble bubble-mobile-2"></div>
                <div class="bubble bubble-mobile-3"></div>
            </div>

            <div class="content">
                <div class="logo"></div>

                <h1>{{ $t('landing.title_download') }}</h1>
                <div v-if="identifier && clientImage" class="client">
                    <div class="icon-client" :style="{ backgroundImage: `url(${clientImage})` }"></div>
                </div>

                <button class="download-button" @click="downloadApp">
                    <div class="icon-android"></div>
                    {{ $t('landing.download_android') }}
                </button>

                <div class="version">{{ $t('landing.version') }} {{ appVersion }}</div>

                <div :href="language === 'es' ? urlChangeLogEs : urlChangeLogEn" class="changelog-link" @click="openChangelog" role="link" tabindex="0">
                    {{ $t('landing.changlog') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

import { Api } from '@/core/api.js'
const api = new Api()

export default {
    props: {
        identifier: {
            type: String,
            required: true
        }
    },
    computed: {
        appVersion() {
            return process.env.VUE_APP_VERSION
        },
        urlDownload() {
            return 'http://alex-itw.s3-eu-west-1.amazonaws.com/apk/andy.apk'
        },
        language: {
            get() {
                var lang = this.$store.getters['getLanguage']
                return lang && lang != 'undefined' ? this.$store.getters['getLanguage'] : 'es'
            },
            set(val) {
                this.$store.commit('changeLanguage', val)
            }
        },
        languages() {
            return this.$store.getters['getLanguages']
        }
    },
    data() {
        return {
            isMobile: false,
            clientImage: '',
            urlChangeLogEs: 'https://andyapp.io/changelog-es',
            urlChangeLogEn: 'https://andyapp.io/changelog'
        }
    },
    mounted() {
        this.isMobile = isMobile()
        window.addEventListener('resize', this.handleResize)
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize() {
            this.isMobile = isMobile()
        },
        downloadApp() {
            const downloadUrl = `${this.urlDownload}?timestamp=${new Date().getTime()}`
            window.open(downloadUrl, '_blank', 'noopener,noreferrer')
        },
        openChangelog() {
            const changelogUrl = this.language === 'es' ? this.urlChangeLogEs : this.urlChangeLogEn
            window.open(changelogUrl, '_blank', 'noopener,noreferrer')
        }
    },
    created() {
        let account_prefix = this.identifier ? this.identifier : false
        api.get('login/account/' + account_prefix).then((response) => {
            this.clientImage = response.data.avatar
            console.log(response.data.avatar)
        })
    }
}

function isMobile() {
    return window.innerWidth <= 1036
}
</script>

<style lang="scss" scoped>
* {
    font-family: $text;
}

.login-top,
.login-bottom {
    @include display-flex();
    @include flex-direction(row);
    @include align-items();
    @include justify-content(space-between);
    @include flex-wrap(wrap);
    height: 40px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: transparent !important;
}
.login-top {
    margin-bottom: 6px;
    display: flex;
    .lang {
        margin-right: 16px;
        margin-left: auto;
        margin-top: 16px;
        @include background($image: img('lang_neutro_blue.svg'), $position: 10px center, $size: 16px);
        padding-left: 20px;
        padding-right: 5px;
        background-color: $color-white;
        width: 78px;
        height: 33px;
        border-radius: 4px;
        select {
            @include background($image: img('down-arrow-374673.svg'), $size: 16px 8px, $position: 35px 14px);
            @include font-size(m);
            -webkit-appearance: none;
            -moz-appearance: none;
            border: none;
            background-color: transparent;
            font-family: $text;
            font-size: 12px;
            font-weight: 600;
            width: 100%;
            height: 40px;
            margin: 0;
            padding: 8px 5px 5px 8px;
            cursor: pointer;
            padding-left: 15px;
            padding-bottom: 12px;
            text-transform: uppercase;
            color: $color-secondary-500;
        }

        option {
            @include font-size(m);
            font-family: $text;
        }
    }
}

.client {
    background-color: white;
    height: 183px;
    width: 420px;
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-client {
    width: 80%;
    height: 80%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.download-page {
    min-height: 100vh;
    background-color: $color-primary-500;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.bubbles {
    position: absolute;
    inset: 0;
}

.bubble {
    position: absolute;
    border-radius: 50%;
    background: rgba(237, 245, 245, 0.19);
}

.bubble-mobile-1 {
    width: 690px;
    height: 690px;
    bottom: -490px;
    left: -380px;
}

.bubble-mobile-2 {
    width: 328px;
    height: 328px;
    top: -238px;
    right: -2px;
}

.bubble-mobile-3 {
    width: 328px;
    height: 328px;
    top: -168px;
    right: -254px;
}

.bubble-1 {
    width: 690px;
    height: 690px;
    bottom: -90px;
    left: -280px;
}

.bubble-2 {
    width: 437px;
    height: 437px;
    bottom: -165px;
    left: -173px;
}

.bubble-3 {
    width: 328px;
    height: 328px;
    top: -238px;
    right: 390px;
}

.bubble-4 {
    width: 328px;
    height: 328px;
    top: -160px;
    right: 150px;
}

.bubble-5 {
    width: 437px;
    height: 437px;
    top: -50px;
    right: -115px;
}

.content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h1 {
        color: $color-white;
    }
    .logo {
        background-image: img('alex_carita_07.svg');
        width: 150px;
        height: 140px;
    }
}

h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.download-button {
    background-color: $color-white;
    color: $color-primary-500;
    border: none;
    border-radius: 4px;
    padding: 12px 24px;
    font-size: 18px;
    font-family: $text-bold;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 16px;

    .icon-android {
        background-image: img('android_157D82.svg');
        width: 21px;
        height: 24px;
    }
}

.download-button:hover {
    background-color: $color-white;
}

.download-button svg {
    width: 20px;
    height: 20px;
}

.version {
    color: $color-white;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 16px;
}

.changelog-link {
    font-size: 18px;
    color: $color-white;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
    transition: color 0.3s ease;
}

.changelog-link:hover {
    color: lighten($color-white, 10%); /* Cambia a un tono más claro en hover */
}
</style>
